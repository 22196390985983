import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@yolo-ui"

interface PageTemplateProps {
  site: {
    siteMetadata: {
      description: string
      title: string
    }
  }
  allMarkdownRemark: {
    totalCount: number
    edges: Array<{
      node: {
        id: string
        frontmatter: {
          title: string
          date: string
          description: string
          imageTitle: string
        }
        fields: {
          slug: string
        }
        excerpt: string
      }
    }>
  }
}

const useAllMarkdownRemark = (): PageTemplateProps => {
  const data = useStaticQuery(graphql`
    query ($type: String = "products") {
      site {
        siteMetadata {
          title
          description
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { fields: { type: { eq: $type } } }
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "DD MMMM, YYYY")
              description
              imageTitle
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `)
  return data
}

const BlogsPage: React.FCWithRoute<PageTemplateProps> = () => {
  const { allMarkdownRemark, site } = useAllMarkdownRemark()
  const posts = allMarkdownRemark.edges
  return (
    <div>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug

        return (
          <div key={node.fields.slug}>
            <Link to={node.fields.slug}>{title}</Link>
          </div>
        )
      })}
    </div>
  )
}

export default BlogsPage
